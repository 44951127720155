@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: 'Neue Machina';
  src: url('../public/fonts/NEUEMACHINA-REGULAR.OTF') format('opentype');
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-Regular.ttf') format('opentype');
} */

/**  ------------------ APP FONTS ------------------ */

/** Neue Machina  */
@font-face {
  font-family: 'Neue Machina';
  src: url('../public/fonts/NeueMachina/NEUEMACHINA-ULTRALIGHT.OTF') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Machina';
  src: url('../public/fonts/NeueMachina/NEUEMACHINA-LIGHT.OTF') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Machina';
  src: url('../public/fonts/NeueMachina/NEUEMACHINA-REGULAR.OTF') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Machina';
  src: url('../public/fonts/NeueMachina/NEUEMACHINA-MEDIUM.OTF') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Machina';
  src: url('../public/fonts/NeueMachina/NEUEMACHINA-BOLD.OTF') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Machina';
  src: url('../public/fonts/NeueMachina/NEUEMACHINA-ULTRABOLD.OTF') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Machina';
  src: url('../public/fonts/NeueMachina/NEUEMACHINA-BLACK.OTF') format('opentype');
  font-weight: 900;
  font-style: normal;
}


/** ROBOTO  */
@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto/ROBOTO-LIGHT.TTF') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto/ROBOTO-LIGHT.TTF') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto/ROBOTO-REGULAR.TTF') format('opentype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto/ROBOTO700.TTF') format('opentype');
  font-weight: 700;
  font-style: normal;
}



body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* LOADING PAGE */
.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; 
  animation: sk-chase-dot 2.0s infinite ease-in-out both; 
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
}

.sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
.sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }

@keyframes sk-chase {
  100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot {
  80%, 100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  } 
}

/* GHOST CSS */
.ghost {
  border-radius: 150px 150px 7px 7px;
  -webkit-animation: ghost 1.5s ease-in-out alternate infinite;
  animation: ghost 1.5s ease-in-out alternate infinite;
}

.corner {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: #161d31;
  position: absolute;
  margin-top: 188px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: -1;
}
.corner.two {
  margin-left: 35px;
}
.corner.three {
  margin-left: 70px;
}
.corner.four {
  margin-left: 105px;
}

.over {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background-color: #450a0a;
  position: absolute;
  margin-top: 188px;
  -webkit-transform: rotate(45deg) translateX(4px) translateY(4px);
  transform: rotate(45deg) translateX(4px) translateY(4px);
}
.over.two {
  margin-left: 35px;
}
.over.three {
  margin-left: 70px;
}
.over.four {
  margin-left: 105px;
}

.ghost-shadow {
  border-radius: 100%;
  -webkit-animation: shadow 1.5s ease-in-out alternate infinite;
  animation: shadow 1.5s ease-in-out alternate infinite;
}

@-webkit-keyframes shadow {
  0% {
    -webkit-transform: scale(1) translateY(0px);
    transform: scale(1) translateY(0px);
  }
  100% {
    -webkit-transform: scale(0.8) translateY(75px);
    transform: scale(0.8) translateY(75px);
  }
}

@keyframes shadow {
  0% {
    -webkit-transform: scale(1) translateY(0px);
    transform: scale(1) translateY(0px);
  }
  100% {
    -webkit-transform: scale(0.8) translateY(75px);
    transform: scale(0.8) translateY(75px);
  }
}
@-webkit-keyframes ghost {
  0% {
    -webkit-transform: scale(1) translateY(0px);
    transform: scale(1) translateY(0px);
  }
  100% {
    -webkit-transform: scale(1) translateY(-40px);
    transform: scale(1) translateY(-40px);
  }
}
@keyframes ghost {
  0% {
    -webkit-transform: scale(1) translateY(0px);
    transform: scale(1) translateY(0px);
  }
  100% {
    -webkit-transform: scale(1) translateY(-40px);
    transform: scale(1) translateY(-40px);
  }
}