::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: #6b7280;
  border-radius: 4rem;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3fbed1;
  --toastify-color-success: #3DB065;
  --toastify-color-warning: #F8BE00;
  --toastify-color-error: #E82F49;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
}


.Toastify__toast-theme--colored.Toastify__toast--default {
  background: #5A5A5A;
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  background: #3fbed1;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #3DB065;
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  background: #F8BE00;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  background: #E82F49;
}

* {
  scroll-behavior: smooth;
}

.bg-gray-900 {
  background-image: linear-gradient(360deg, #1e1e1e 24%, #39393d 100%);
}


option {
  background: #404040;
}

.input-date {
  color-scheme: dark;
}

.sidebar::-webkit-scrollbar {
  display: none;
}
